import { EventEmitter } from "events";

const BatchServiceEventEmitter = new EventEmitter();
export default class ViewedStoriesService {
  private fetchInterval: number = 5000; // Every 7 seconds
  private intervalId: NodeJS.Timeout | null = null;

  constructor() {
    this.initializeService();
  }

  private initializeService() {
    this.startStoryFetchProcess();
  }

  // Start the process to periodically fetch and emit stories every 7 seconds
  private startStoryFetchProcess() {
    this.intervalId = setInterval(() => {
      this.fetchViewedStories();
    }, this.fetchInterval);
  }

  // Fetch viewed stories from localStorage and emit an event with the data
  public fetchViewedStories() {
    try {
      const storedStories = localStorage.getItem("viewedStories");
      if (storedStories) {
        const viewedStories = JSON.parse(storedStories) as string[];
        if (viewedStories.length > 0) {
          // Emit an event with the viewed stories data
          BatchServiceEventEmitter.emit("send-data", {
            storyIds: viewedStories,
          });
          // Clear viewed stories from localStorage after emitting
          localStorage.removeItem("viewedStories");
        } else {
          console.log("No stories to emit.");
        }
      }
    } catch (error) {
      console.error("Error fetching viewed stories from localStorage", error);
    }
  }

  // Clear the interval when the service is no longer needed
  public stopService() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      console.log("Story fetch process stopped.");
    }
  }
}

// Example instantiation of the service
export const SendDataToServer = new ViewedStoriesService();

export { BatchServiceEventEmitter };

"use client";
import { FeedFilter } from "@/components/FeedFilter";
import { Box, Row } from "@/components/styled";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FeedFilterData } from "@/utils/constants";
import { OnboardingModal } from "@/components/OnboardingModal";
import { useModal } from "@/hooks/useModal";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { useRouter } from "next/navigation";
import { useCreateStripeCustomer } from "@/hooks/useCreateStripeCustomer";
import { useUser } from "@/hooks/useUser";
import { useViewStories } from "@/hooks/useViewStories";
import { BatchServiceEventEmitter } from "@/services/cachingService";

export const Home = () => {
  const { toggleModal, isOpen } = useModal();
  const { isLoggedin } = useIsLoggedIn();
  const router = useRouter();
  const { createStripeCustomer, data } = useCreateStripeCustomer();
  const { data: UserData } = useUser();

  const { viewStories } = useViewStories();

  useEffect(() => {
    // Define the event listener function
    const handleSendData = async (args: { storyIds: string[] }) => {
      try {
        await viewStories({
          variables: { input: { storyIds: args.storyIds } },
        });
        console.log("Stories sent to server successfully!");
      } catch (error) {
        console.error("Error sending stories:", error);
      }
    };

    // Subscribe to the "send-data" event
    BatchServiceEventEmitter.on("send-data", handleSendData);

    return () => {
      // Unsubscribe from the "send-data" event on cleanup
      BatchServiceEventEmitter.off("send-data", handleSendData);
    };
  }, []);

  useEffect(() => {
    if (!UserData || UserData.me.stripeCustomerId === null) {
      createStripe();
    }
  }, []);

  const createStripe = async () => {
    try {
      const response = await createStripeCustomer();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Row
        gap={25}
        justifyContent={"center"}
        py={["md", "2xl"]}
        alignItems="center"
      ></Row>
      <FeedFilter feedFilter={FeedFilterData.homepage} mobileAlign="c" />
      <OnboardingModal onClose={toggleModal} isVisible={isOpen} />
    </Box>
  );
};

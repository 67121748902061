import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

const VIEW_STORIES_DOCUMENT = graphql(`
  mutation AddViewsToStories($input: ViewStoriesInput!) {
    viewStories(input: $input)
  }
`);

export const useViewStories = () => {
  const [viewStories, { data, loading, error }] = useMutation(
    VIEW_STORIES_DOCUMENT
  );
  return {
    viewStories,
    data,
    loading,
    error,
  };
};

import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const CREATE_STRIPE_CUSTOMER_DOCUMENT = graphql(`
  mutation CreateStripeCustomer {
    createStripeCustomer {
      stripeCustomerId
    }
  }
`);

export const useCreateStripeCustomer = () => {
  const [createStripeCustomer, { data, loading, error }] = useMutation(
    CREATE_STRIPE_CUSTOMER_DOCUMENT
  );
  return {
    createStripeCustomer,
    data,
    loading,
    error,
  };
};

import React from "react";
import { Box, HeadingLabelsText, Row, Text } from "../styled";
import { BASE_COLORS } from "@/theme";
import Link from "next/link";
import { usePathname } from "next/navigation";
export type FeedFilterProps = {
  feedFilter: {
    label: string;
    pushto: string;
  }[];
  mobileAlign?: "l" | "c" | "r";
};

export const FeedFilter: React.FC<FeedFilterProps> = ({
  feedFilter,
  mobileAlign,
}) => {
  const pathname = usePathname();
  const urlSections = pathname.split("/");
  const extractedlink = urlSections[urlSections.length - 1];

  const alignment =
    mobileAlign === "l" ? "start" : mobileAlign === "c" ? "center" : "end";

  return (
    <Row
      width={"100%"}
      borderBottom={`1px solid ${BASE_COLORS.primaryTransparent}`}
      boxShadow={"0px 1px 0px 0px #FF575712"}
      overflowX="auto"
      justifyContent={[alignment, "center"]}
      alignItems={"center"}
      px={["md", "lg"]}
    >
      <Row width={"fit-content"} gap={["3xl", "6xl"]} alignItems={"center"}>
        {feedFilter.map((item, index) => (
          <Box
            key={index}
            minWidth={"fit-content"}
            alignItems={"center"}
            borderBottom={
              extractedlink === item.pushto
                ? `1px solid ${BASE_COLORS.primary}`
                : ""
            }
            style={{ cursor: "pointer" }}
            pb={"xs"}
          >
            <Link href={item.pushto} style={{ textDecoration: "none" }}>
              <HeadingLabelsText
                color={extractedlink === item.pushto ? "primary" : "foreground"}
              >
                {item.label}
              </HeadingLabelsText>
            </Link>
          </Box>
        ))}
      </Row>
    </Row>
  );
};

"use client";
import { Box, CenterBox, Row, Text } from "@/components/styled";
import { BouncyModal } from "@/components/styled/BouncyModal";
import { useModal } from "@/hooks/useModal";
import Onboarding from "../Onboarding/Onboarding";
import { FC } from "react";
type OnboardingModalTypes = {
  isVisible: boolean;
  onClose: () => void;
};
export const OnboardingModal: FC<OnboardingModalTypes> = ({
  isVisible,
  onClose,
}) => {
  return (
    <BouncyModal
      isOpen={isVisible}
      onClose={onClose}
      width={["90%", "40%"]}
      height="fit-content"
    >
      <Box width="100%">
        <Onboarding postAction={onClose}/>
      </Box>
    </BouncyModal>
  );
};
